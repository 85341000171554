import React from "react"

import { withMembership } from "./withMembership"
import { Intro } from "../../Intro/Intro"
import { StyledContainer } from "./Membership.styled"

export const Membership = withMembership(
  ({ title, content }: Props): JSX.Element => (
    <StyledContainer spacing={`lg`} topSpacing={`lg`} width={`xl`}>
      <Intro title={title} backClick={null} backLink={"/account/dashboard"} />
      {content}
    </StyledContainer>
  )
)

export interface Props {
  title?: string
  content?: any
  loading?: boolean
  handleCheckout?: (price: string) => void
}
