import React, { useMemo } from "react"

import { useCore } from "../../../hooks/useCore"
import { useStripe } from "../../../hooks/useStripe"

export const withMembership = Component =>
  React.memo(({ name = "Membership", page }) => {
    const {
      helpers: { sanityContent },
    } = useCore()
    const { openCheckout, loading } = useStripe()

    const title = page?.title
    const content = sanityContent(page?.content)

    Component.displayName = name
    return useMemo(() => <Component title={title} content={content} handleCheckout={openCheckout} loading={loading} />, [loading])
  })
